import React, { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import { connectWallet, Manager, Factory, Multicalls, Trophies, VRF, Token } from "../scripts/contractApi.js";
import { defaults } from "../constants/defaultValues.js";

const AcceptOwnership = forwardRef(({ deployedAddress, onConsumerAdded: onOwnershipAccepted, testnet }, ref) => {
  const [step, setStep] = useState(false);

  useImperativeHandle(ref, () => ({
    reset() {
      setStep(false);
    },
  }));

  const handleButtonClick = async () => {
    try {
      var result = await Token.acceptOwnership(deployedAddress);
      console.log(result.status);
      if (result.status === 1) {
        setStep(true);
        onOwnershipAccepted();
      }
    } catch (error) { }
  };

  return (
    <div className="DeploymentStep-container">
      <p className="DeploymentStep-title">Accept Ownership</p>

      <button className="StepButton" onClick={handleButtonClick}>
        {`Send tx ${step ? "✅" : ""}`}
      </button>
    </div>
  );
});

export default AcceptOwnership;
