export const defaultsTestnet = {
    //COMMON
    
    //TESTNET
    NAME: "The Gainlings Season 3",
    TAG: "TGS",
    VRF_COORDINATOR_ADDRESS: "0x5CE8D5A2BC84beb22a398CCA51996F7930313D61",
    KEY_HASH: "0x1770bdc7eec7771f7ba4ffd640f34260d7f095b79c92d34a5b2551d6f6cfd2be",
    SUBSCRIPTION_ID: "9851361784084239491245569309529302591709985878901948983820656760078515817468",
    //
    TROPHY_CONTRACT_ADDRESS: "0xbDF385aF064503662E27621B0bE37Be21B81bD17", 

    STORAGE_CONTRACT_ADDRESS: "0x536DEe66B2bcb33d2Cf73602a3c4d6BC45964300", 
    LIBRARY_CONTRACT_ADDRESS: "0x4C05c6b8EC0Ed8DC9cb0c83D8a4Cf7B19D2cB2Ef", 
    MARKET_CONTRACT_ADDRESS: "0xD0aAfa6B497048EEBC262169a47Ea7c031abDfd0", 
    FACTORY_CONTRACT_ADDRESS : "0xfd87F74A4aa85b77C250895BB8D2c2dcAc1B0108",
    MANAGER_CONTRACT_ADDRESS : "0x771b10AC9585452de9140750b9ffA13BeB8c1b7D",
    //ANIMATION_URL: "https://hidden-frog-6004.on.fleek.co/",
    ANIMATION_URL: "",
    PRICE_PER_TOKEN: "1000000000000000",

    MINT_LENGTH: "1800", 
    PREP_LENGTH: "300", 
}

export const defaultsMainnet = {
    //COMMON
    
    //TESTNET
    NAME: "The Gainlings Season 3",
    TAG: "TGS",
    VRF_COORDINATOR_ADDRESS: "0x3C0Ca683b403E37668AE3DC4FB62F4B29B6f7a3e",
    KEY_HASH: "0x8472ba59cf7134dfe321f4d61a430c4857e8b19cdd5230b09952a92671c24409",
    SUBSCRIPTION_ID: "114313706143340438528375865325983566377810135860175972854625005351840047166232",
    //
    TROPHY_CONTRACT_ADDRESS: "0xfBe074b336831aeD6f646DeC36a1017d70B5dC4f", 

    STORAGE_CONTRACT_ADDRESS: "0x68677c97b0C2B3120F9c03762Bb124fA7b90F054", 
    LIBRARY_CONTRACT_ADDRESS: "0x75254ae3A29Fc458C5d2519e3D0c4D4Dff9d4D76", 
    MARKET_CONTRACT_ADDRESS: "0x0F0b8362888444b4898D6B9b40E544A5191D2E79", 
    FACTORY_CONTRACT_ADDRESS : "0xC6bf973AF7EDA309c7CA709fF48bc23DD9476632",
    MANAGER_CONTRACT_ADDRESS : "0xD74505ac0784cc2c84141bde8ed2A07078bcDEe8",
    //ANIMATION_URL: "https://hidden-frog-6004.on.fleek.co/",
    ANIMATION_URL: "",
    PRICE_PER_TOKEN: "1000000000000000",

    MINT_LENGTH: "1800", 
    PREP_LENGTH: "300", 
}

export const defaults = (testnet) => {
    if(testnet){
        return defaultsTestnet;
    }
    else{
        return defaultsMainnet;
    }
}