import React, { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import { connectWallet, Manager, Factory, Multicalls, Trophies } from "../scripts/contractApi.js";
import { defaults } from "../constants/defaultValues.js";

const TrophyAdmin = forwardRef(({ root, onMerkleRootSet, testnet }, ref) => {
  const [step, setStep] = useState(false);
  const [trophyContractAddress, setTrophyContractAddress] = useState(defaults(testnet).TROPHY_CONTRACT_ADDRESS);

  useEffect(() => {
    setTrophyContractAddress(defaults(testnet).TROPHY_CONTRACT_ADDRESS);
  }, [testnet]);

  
  useImperativeHandle(ref, () => ({
    reset() {
      setStep(false);
    },
  }));
  const handleTrophyContractAddressChange = (e) => {
    setTrophyContractAddress(e.target.value);
  };
  const handleButtonClick = async () => {
    try {
      var result = await Trophies.setAllowedAddress(root, true);
      if (result.status) {
        onMerkleRootSet();
        setStep(true);
      }
    } catch (error) {}
  };

  return (
    <div className="DeploymentStep-container">
      <p className="DeploymentStep-title">Allow contract to mint trophies</p>
      <p className="DeploymentStep-inputfield-title">Trophy Contract Address</p>
      <input type="text" className="DeploymentStep-input" value={trophyContractAddress} onChange={handleTrophyContractAddressChange} />
      <button className="StepButton" onClick={handleButtonClick}>
        {`Send tx ${step ? "✅" : ""}`}
      </button>
    </div>
  );
});

export default TrophyAdmin;
