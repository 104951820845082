import React, { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import { connectWallet, Manager, Factory, Multicalls, Trophies, VRF, Token } from "../scripts/contractApi.js";
import { defaults } from "../constants/defaultValues.js";

const WithdrawFunds = forwardRef(({ deployedAddress, onFundsWithdrawn, testnet }, ref) => {
  const [step, setStep] = useState(false);
  const [contractAddress, setContractAddress] = useState(deployedAddress);
  useImperativeHandle(ref, () => ({
    reset() {
      setStep(false);
    },
  }));
  const handleContractAddressChange = (e) => {
    setContractAddress(e.target.value);
  };  
  const handleButtonClick = async () => {
    try {
      var result = await Token.withdrawFunds(contractAddress);
      console.log(result.status);
      if (result.status === 1) {
        setStep(true);
        onFundsWithdrawn();
      }
    } catch (error) { }
  };

  return (
    <div className="DeploymentStep-container">
      <p className="DeploymentStep-title">Withdraw Funds</p>
      <p className="DeploymentStep-inputfield-title">Only do this AFTER the winner has claimed its rewards!</p>
      <p className="DeploymentStep-inputfield-title">Contract Address</p>
      <input type="text" className="DeploymentStep-input" value={contractAddress} disabled={false} onChange={handleContractAddressChange} />
      <button className="StepButton" onClick={handleButtonClick}>
        {`Send tx ${step ? "✅" : ""}`}
      </button>
    </div>
  );
});

export default WithdrawFunds;
