import React, { useState, useImperativeHandle, forwardRef } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import { connectWallet, Manager, Factory, Multicalls, Trophies, Token } from "../scripts/contractApi.js";
import { defaults } from "../constants/defaultValues.js";

const ContractParamsAdvanced = forwardRef(({ onContractParamsSet, deployedAddress, testnet }, ref) => {
  const [step, setStep] = useState(false);

  const [weightMultiplier, setWeightMultiplier] = useState(1);
  const [weightDivider, setWeightDivider] = useState(2);
  const [cooldownBuff, setCooldownBuff] = useState(250);
  const [mintsPerWallet, setMintsPerWallet] = useState(25);
  const [mintsPerTx, setMintsPerTx] = useState(5);

  useImperativeHandle(ref, () => ({
    reset() {
      setStep(false);
    },
  }));

  const handleWeightMultiplierChange = (e) => {
    setWeightMultiplier(e.target.value);
  };
  const handleWeightDividerChange = (e) => {
    setWeightDivider(e.target.value);
  };
  const handleCooldownBuffChange = (e) => {
    setCooldownBuff(e.target.value);
  };
  const handleMintsPerWalletChange = (e) => {
    setMintsPerWallet(e.target.value);
  };
  const handleMintsPerTxChange = (e) => {
    setMintsPerTx(e.target.value);
  };


  const handleButtonClick = async () => {
    try {
      const result = await Token.setContractParamsAdvanced(
        deployedAddress,
        weightMultiplier,
        weightDivider,
        cooldownBuff,
        mintsPerWallet,
        mintsPerTx,
      );
      console.log("Result:", result);
      if (result) {
        onContractParamsSet();
        setStep(true);
      }
    } catch (error) {}
  };

  return (
    <div className="DeploymentStep-container">
      <p className="DeploymentStep-title">Set Contract Params Advanced</p>

      <p className="DeploymentStep-inputfield-title">Weight Multiplier</p>
      <input type="text" className="DeploymentStep-input" value={weightMultiplier} onChange={handleWeightMultiplierChange} />

      <p className="DeploymentStep-inputfield-title">Weight Divider</p>
      <input type="text" className="DeploymentStep-input" value={weightDivider} onChange={handleWeightDividerChange} />

      <p className="DeploymentStep-inputfield-title">Cooldown Buff</p>
      <input type="text" className="DeploymentStep-input" value={cooldownBuff} onChange={handleCooldownBuffChange} />

      <p className="DeploymentStep-inputfield-title">Mints Per Wallet</p>
      <input type="text" className="DeploymentStep-input" value={mintsPerWallet} onChange={handleMintsPerWalletChange} />

      <p className="DeploymentStep-inputfield-title">Mints per Tx</p>
      <input type="text" className="DeploymentStep-input" value={mintsPerTx} onChange={handleMintsPerTxChange} />

      <button className="StepButton" onClick={handleButtonClick}>
        {`Send tx ${step ? "✅" : ""}`}
      </button>
    </div>
  );
});

export default ContractParamsAdvanced;
